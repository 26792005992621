<template>
  <div class="login">
    <div class="left">农贸市场管理系统</div>
    <div class="right">
      <el-card class="box-card">
        <div
          slot="header"
          class="clearfix"
          style="text-align:center;font-size:20px"
        >
          <span>登录</span>
        </div>
        <div class="bottom">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="80px"
            class="demo-ruleForm"
          >
            <el-form-item label="账号:" prop="account">
              <el-input
                placeholder="请输入账号"
                v-model="form.account"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password">
              <el-input
                placeholder="请输入密码"
                v-model="form.password"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码:" prop="code">
              <span style="display:flex"
                ><el-input
                  placeholder="请输入验证码"
                  v-model="form.code"
                ></el-input>
                <img
                  :src="imageurl.captcha_image_content"
                  alt=""
                  @click="getcaptchas"
                  style="margin-left:30px;height:40px"
              /></span>
            </el-form-item>
            <el-form-item class="form_item_login">
              <span>
                <el-checkbox
                  label="记住账号密码"
                  name="type"
                  style="margin-right:100px"
                  v-model="is_remember"
                ></el-checkbox>
                <el-button size="medium " type="primary" @click="submitForm"
                  >登录</el-button
                >
              </span>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { captchas, authorizations } from '../../api/login'
import { setLocat, removeLocat, getLocat } from '../../utile/local'

export default {
  name: 'login',
  data () {
    return {
      is_remember: true,
      form: {
        account: '',
        password: '',
        code: ''
      },
      content: '验证码不能为空',
      imageurl: '', // 图形验证码
      rules: {
        account: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //  登陆按钮
    submitForm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            username: this.form.account,
            password: this.form.password,
            captcha_key: this.imageurl.captcha_key,
            captcha_code: this.form.code
          }
          authorizations(data)
            .then(res => {
              if (res.code === 200) {
                localStorage.setItem('token', res.data.access_token)
                this.$router.replace('/home/market_list')
                this.$message.success('登陆成功')
                if (this.is_remember) {
                  setLocat('account', this.form.account)
                  setLocat('password', this.form.password)
                } else {
                  removeLocat('account')
                  removeLocat('password')
                }
              }
            })
            .catch(err => {
              this.form.code = ''
              this.$message.error(`${err.msg}`)
              this.getcaptchas()
            })
        } else {
          return false
        }
      })
    },
    // 发送请求图片
    getcaptchas () {
      captchas().then(res => {
        // console.log(res)
        this.imageurl = res.data
      })
    }
  },
  mounted () {
    this.getcaptchas()
    this.form.account = getLocat('account')
    this.form.password = getLocat('password')
  }
}
</script>

<style lang="less" scoped>
.login {
  background-color: skyblue;
  background-image: url('https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3335029125,170207112&fm=26&gp=0.jpg');
  background-size: cover;
  height: 100%;
  .left {
    text-align: center;
    margin-bottom: 50px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 800;
    padding-top: 100px;
  }
  .right {
    margin: 0 auto;
    width: 500px;

    .bottom {
      img {
        width: 200px;
        height: 40px;
      }
      .form_item_login {
        // width: 100%;
        display: flex;
        // justify-content: space-between;
      }
    }
  }
}
</style>
