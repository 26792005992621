function setLocat (key, value) {
  window.localStorage.setItem(key, value)
}
function getLocat (key) {
  return window.localStorage.getItem(key)
}
function removeLocat (key) {
  window.localStorage.removeItem(key)
}

export { setLocat, getLocat, removeLocat }
